/*
 * Variables
 *
 * Table of Contents
 *
 * Colors
 * Body
 * Grid
 * Typography
 * Spacing
 * Components
 * Custom Media Queries
 */

:root {
    /* Colors */
    --brand-primary: #9f005b; /* plum */
    --brand-secondary: #008f94; /* teal */
    --brand-primary-forum: #f5bd47; /* gold */
    --brand-primary-mills: #f4763b; /* orange */
    --brand-gray: #b4b2b0; /* simon gray */
    --brand-error: #9f005b; /* also plum */
    --brand-success: #008f94; /* also teal */
    --brand-turquoise: #00abe6; /* spring 2021 turquoise */
    --brand-primary-lighter: #fcf7fa; /* lighter plum */
    --white: #fff;
    --black: #000;
    --gray-pale: #f7f7f7;
    --gray-lighter: #f1f1f1;
    --gray-light: #ebebeb;
    --gray: #757575;
    --gray-dark: #58595a;
    --gray-darker: #333;
    --family-purple: #b43694;
    --family-red: #e43d30;
    --family-yellow: #cdde00;
    --family-dark-red: #b92d2c;
    --family-teal: #3bbfad;
    --family-light-blue: #00a6ce;
    --family-dark-blue: #008bad;
    --holiday-red: #d20b37;
    --family-aqua: #65c5b3;
    --family-red2: #f04937;
    --family-blue: #00b3dc;
    --family-pink: #f16b81;
    --family-yellow-green: #d3df4e;
    --family-purple2: #9d1d86;
    --business-red: #E63E30;
    /* Body */
    --body-bg: var(--white);
    /*
   * Grid - including breakpoints, containers and columns
   *
   * Currently the grid is being build with sass until sufficient time is allowed to move over to a full PostCSS version
   * To access grid setting and code see ../scss/bootstrap-grid/bootstrap-grid.scss
   * Which compiles to _grid.css via gulp sass task - DO NOT EDIT THIS FILE
   *
   * Below are the grid breakpoints copied from ../scss/bootstrap-grid/bootstrap-grid.scss - please update if breakpoints change
   */
    --grid-xs: 0;
    --grid-sm: 544px;
    --grid-md: 768px;
    --grid-lg: 992px;
    --grid-xl: 1200px;
    /*
   * Typography
   *
   * The type scale is also built with sass until sufficient time is allowed to move over to a full PostCSS version
   * To access scale and global typography settings see ../scss/modular-scale.scss
   * Which compiles to _typescale.css via gulp sass task - DO NOT EDIT THIS FILE
   *
   * Below are the global html and body base font size and line height.
   * All typography units are being set in rems
   */
    --html-base-size: 108%; /* var(--base-font-size) = 17.28px per design guide */
    --base-font-size: 0.9256rem; /* 15.996px per design guide */
    --header-line-height: 1;
    --body-line-height: 1.25;
    --hr-border-width: 1px;
    --hr-border-color: var(--brand-gray);
    /* Icons */
    --icon-size-xl: 1.596rem; /* 27.6410px */
    --icon-size-lg: 1.45rem; /* 25px */
    --icon-size-md: 1.04rem; /* 18px */
    --icon-size-sm: 0.9256rem; /* 15.996px per design guide */
    --icon-size-xs: 0.64137rem; /* 11.3px */
    /* Spacing */
    --spacer: var(--base-font-size); /* 15.996px per design guide */
    --spacer-x: var(--spacer);
    --spacer-y: var(--spacer);
    --font-size-h1: 2.29824rem; /* 39.8px */
    --font-size-h2: 1.9152rem; /* 33.094px */
    --font-size-h3: 1.596rem; /* 27.6410px */
    --font-size-h4: 1.33rem; /* 23.03424px */
    --font-size-h5: 1.10833rem; /* 19.1952px */
    --font-size-h6: var(--base-font-size); /* 15.996px */
    --display1-size: 5.71876rem; /* 99.043px */
    --display2-size: 4.76563rem; /* 82.535px */
    --display3-size: 3.97136rem; /* 68.780px */
    --display4-size: 3.30947rem; /* 57.3163px */
    --font-size-small: 0.76968rem; /* 13.3px */
    --font-size-xsmall: 0.64137rem; /* 11.083px */
    /*
   * Components
   *
   * Forms
   * Buttons
   * Tables
   * Lists
   * Dropdowns
   * Jumbotrons
   * Navs/Navbar/Headers
   * Footers
   * Tabs
   * Pills
   * Mobile Menu
   * Modals
   * Breadcrumbs
   * Pagination/Pager
   * Accordions
   * Cards
   * Sections
   * Search
   * Sliders
   * Z-Index List
   */
    /* Forms  */
    --input-height: 53px; /* calc((var(--input-padding-y) * 2) + (var(--input-font-size) * 1rem) + (3 * (var(--input-btn-border-width) * 2))) */
    --input-font-size: 0.7696rem;
    --input-padding-x: 1.2731rem;
    --input-padding-y: 0.9722rem;
    --form-phone-prefix: 0.7696rem;
    --input-color: var(--black);
    --input-bg: var(--white);
    --input-border-color: var(--gray);
    --input-border-color-active: var(--black);
    --input-color-placeholder: var(--gray);
    --input-color-placeholder-active: var(--black);
    --input-btn-border-width: 1px;
    --input-bg-disabled: var(--gray-lighter);
    --form-group-margin-bottom: 1.62037037037037rem; /* Spacing between horizontally stacked form elements */
    --form-group-no-header-top-margin: 1.798rem; /* Only add top margin to the first child of a stack of form groups when there isn't a header */
    --cursor-disabled: not-allowed;
    --form-header-font-size-lg: var(--font-size-h3);
    --form-header-border-color: var(--brand-gray);
    --form-header-border-width: 1px;
    --form-header-margin-mobile: 1.10833rem;
    --form-header-margin-desktop: 1.596rem;
    /* Custom File Input */
    --file-input-min-width: 290px;
    --file-button-color: var(--white);
    --file-button-background: var(--black);
    --file-button-label: "BROWSE";
    --file-input-placeholder: "Choose File...";
    /* Select Arrow */
    --icon-down-arrow: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSItMzg1IDMwMS41IDIwIDEwLjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgLTM4NSAzMDEuNSAyMCAxMC41OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojNzY3Njc2O30NCjwvc3R5bGU+DQo8Zz4NCgk8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9Ii0zNzUsMzEwLjggLTM4My4xLDMwMy45IC0zODIuMSwzMDIuNyAtMzc1LDMwOC44IC0zNjcuOSwzMDIuNyAtMzY2LjksMzAzLjkgCSIvPg0KPC9nPg0KPC9zdmc+DQo=");
    --icon-down-arrow-white: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyMCA4LjkiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDIwIDguOTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxMCw4LjkgMCwxLjIgMC45LDAgMTAsNyAxOS4xLDAgMjAsMS4yICIvPg0KPC9zdmc+DQo=");
    /* Checkbox Icon */
    --checkbox-color: var(--black);
    --checkbox-size: 1rem;
    --icon-checkbox: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSItMzc5LjQgMzAzLjcgMTAuMyA3LjMiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgLTM3OS40IDMwMy43IDEwLjMgNy4zOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8cGF0aCBkPSJNLTM3NS41LDMxMUMtMzc1LjUsMzExLTM3NS41LDMxMS0zNzUuNSwzMTFjLTAuMywwLTAuNSwwLTAuNy0wLjJsLTMuMS0zLjJsMS40LTEuNGwyLjQsMi41bDUuMS01bDEuNCwxLjRsLTUuOCw1LjcNCgkJQy0zNzUsMzExLTM3NS4zLDMxMS0zNzUuNSwzMTF6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==");
    --icon-checkbox-white: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMC43IDgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEwLjcgODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPHBhdGggY2xhc3M9InN0MCIgZD0iTTQsOEw0LDhDMy43LDgsMy40LDcuOCwzLjEsNy41TDAsNC4zbDEuOC0xLjhMNCw0LjhMOC45LDBsMS44LDEuOEw0LjksNy42QzQuNyw3LjgsNC40LDgsNCw4eiIvPg0KPC9zdmc+DQo=");
    /* Radio Buton Icon */
    --icon-radio: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDE3IDE3IiB4bWw6c3BhY2U9InByZXNlcnZlIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxNyAxNyI+DQogIDxjaXJjbGUgY3g9IjguNSIgY3k9IjguNSIgcj0iOC41Ii8+DQo8L3N2Zz4NCg==";
    /* Admin */
    --admin-view-publish-height: 70px;
    --scNavbar-height: 74px;
    --mall-alert-height: 40px;
    /* Buttons */
    --btn-padding-y: calc(var(--input-padding-y));
    --btn-padding-x: 1.388rem;
    --btn-icon-size: var(--input-font-size);
    --btn-border-radius: 4px;
    --btn-font-size: var(--input-font-size);
    --btn-primary-color: var(--white);
    --btn-primary-bg: var(--black);
    --btn-primary-border: transparent;
    --btn-primary-bg-active: var(--brand-gray);
    --btn-primary-color-active: var(--white);
    --btn-primary-color-active-focus: var(--gray-lighter);
    --btn-secondary-color: var(--black);
    --btn-secondary-bg: var(--white);
    --btn-secondary-bg-active: var(--brand-gray);
    --btn-secondary-color-active: var(--white);
    --btn-secondary-color-active-focus: var(--gray-lighter);
    --btn-secondary-border: transparent;
    --btn-primary-outline-color: var(--black);
    --btn-primary-outline-color-active: var(--brand-gray);
    --btn-secondary-outline-color: var(--white);
    --btn-secondary-outline-color-active: var(--brand-gray);
    --enabled-rounded: false; /* Turns on rounded corners */
    --btn-block-spacing-y: 1.596rem; /* Spacing between horizontally stacked buttons */
    /* Tables */
    --table-border-width: 1px;
    --table-cell-padding: var(--spacer);
    --table-border-color: var(--black);
    --table-sm-cell-padding: calc(var(--spacer) / 2); /* 17.28px / 2 = 8.64px */
    --table-bg-accent: var(--gray-lighter);
    /* Lists */
    --vertical-list-margin: 0.1736rem;
    --list-inline-padding: var(--spacer);
    --list-bullet-size: var(--icon-size-xs);
    --diamond-list-bullet-color: var(--brand-primary);
    /* Dropdowns */
    --dropdown-border-color: var(--brand-gray);
    --dropdown-border-width: 1px;
    --dropdown-bg: var(--white);
    --dropdown-padding-y: 1.01rem;
    --dropdown-toggle-border-bg: var(--gray);
    --dropdown-toggle-border-height: 3px;
    --dropdown-item-padding-y: 0.5541rem;
    --dropdown-item-padding-x: 1.736111rem;
    --dropdown-link-color: var(--navbar-nav-link-color);
    --dropdown-link-active-color: var(--black);
    --dropdown-link-font-size: var(--base-font-size);
    --dropdown-link-hover-color: var(--white);
    --dropdown-link-hover-bg: var(--brand-gray);
    --dropdown-link-active-color: var(--black);
    --dropdown-link-active-bg: var(--brand-gray);
    --dropdown-link-disabled-color: var(--gray-lighter);
    --dropdown-divider-padding-y: 1rem;
    /* Jumbotrons */
    --jumbotron-padding: var(--spacer);
    --jumbotron-bg: var(--gray-lighter);
    /* Nav */
    --nav-item-inline-spacer: var(--spacer);
    --nav-item-margin: var(--spacer);
    --nav-link-color: #6e6e6e; /* var(--gray) */
    --nav-link-padding: calc( (var(--navbar-height) - var(--navbar-logo-height)) / 2 );
    --nav-link-hover-color: var(--black);
    --nav-disabled-link-color: var(--brand-gray);
    --nav-disabled-link-hover-color: var(--brand-gray);
    --nav-disabled-link-hover-bg: var(--gray-lighter);
    /* Navbars */
    --navbar-nav-font-size: 0.6414rem;
    --navbar-height: 74px;
    --navbar-sub-height: 55px;
    /*--navbar-mobile-height: 55px;*/
    --navbar-mobile-height: 60px;
    --navbar-logo-height: 54px;
    --navbar-logo-mobile-height: 42px;
    --navbar-nav-spacer: 1.9152rem;
    --navbar-logo-margin-y: calc( (var(--navbar-height) - var(--navbar-logo-height)) / 2 );
    --navbar-padding-y: 0;
    --navbar-padding-x: 0;
    --navbar-brand-padding-y: 0;
    --navbar-brand-padding-x: 0;
    --navbar-nav-link-size: var(--navbar-nav-font-size);
    --navbar-nav-link-padding-y: calc( (var(--navbar-height) - var(--navbar-nav-link-size)) / 2 );
    --navbar-nav-link-color: #6e6e6e; /* var(--gray) */
    --navbar-nav-link-hover-color: var(--black);
    --navbar-nav-link-active-color: var(--black);
    --navbar-light-bg: var(--brand-gray);
    --navbar-light-color: var(--white);
    --navbar-light-active-color: var(--gray);
    --navbar-light-hover-color: var(--gray);
    --navbar-dark-bg: var(--black);
    --navbar-dark-color: var(--white);
    --navbar-dark-active-color: var(--gray);
    --navbar-dark-hover-color: var(--gray);
    --navbar-sidepanel-link-color: var(--white);
    --navbar-sidepanel-active-link-color: var(--gray);
    --navbar-sidepanel-secondary-link-color: var(--gray-lighter);
    --navbar-divider-spacing: 21px;
    --navbar-global-height: 40px;
    /* Footers */
    --footer-banner-bg: var(--gray-pale);
    --footer-banner-color: var(--black);
    --footer-banner-active-color: var(--gray);
    --footer-banner-font-size: var(--navbar-nav-font-size);
    --footer-banner-padding-y: 6px;
    --footer-banner-padding-x: 0;
    --footer-icon-hover-color: var(--gray);
    --footer-social-spacing: 1.9152rem;
    --footer-social-icon-size: 1.8515rem; /* Previously set to --icon-size-lg */
    --footer-links-font-size: var(--font-size-small);
    --footer-divider-padding-y: 1.9152rem;
    --footer-divider-padding-x: 0;
    /* Default Footer */
    --footer-default-spacing: 1.5rem;
    --footer-default-border-width: 1px;
    --footer-default-border-color: var(--brand-gray);
    --footer-default-mobile-padding-y: 1.33rem;
    --footer-default-mobile-padding-x: 0.86806rem;
    --footer-default-mobile-font-size: var(--base-font-size);
    /* Tabs */
    --nav-tabs-border-width: 1px;
    --nav-tabs-border-color: var(--gray);
    --nav-tabs-link-hover-border-color: var(--gray);
    --nav-tabs-active-link-hover-border-color: var(--brand-primary);
    --nav-tabs-active-link-hover-bg: var(--white);
    --nav-tabs-active-link-hover-color: var(--black);
    /* Pills */
    --nav-pills-active-link-color: var(--white);
    --nav-pills-active-link-bg: var(--gray-dark); /* var(--brand-gray); */
    /* Modals */
    --modal-backdrop-bg: var(--black);
    --modal-backdrop-opacity: 0.5;
    --modal-content-border-color: transparent;
    --modal-content-border-width: 0;
    --modal-content-bg: var(--white);
    --modal-inner-padding: 33.095px;
    --modal-dialog-margin: 90px 15px;
    --modal-dialog-sm-up-margin-y: 100px;
    --modal-sm: 500px;
    --modal-md: 650px;
    --modal-lg: 835px;
    --modal-xl: 920px;
    --modal-close-size: var(--icon-size-lg);
    /* Close */
    --close-color: var(--black);
    --close-hover-opacity: 1.0;
    /* Breadcrumbs */
    --breadcrumb-padding-y: var(--spacer);
    --breadcrumb-padding-x: var(--spacer);
    --breadcrumb-bg: var(--white);
    --breadcrumb-item-padding: 25px;
    --breadcrumb-divider-color: var(--black);
    --breadcrumb-item-color: var(--brand-gray);
    --breadcrumb-active-color: var(--black);
    --breadcrumb-divider: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNi45IDExLjciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDYuOSAxMS43OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojQjRCMkIwO30NCjwvc3R5bGU+DQo8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjEuMSwwIDAsMS4xIDQuOCw1LjggMCwxMC42IDEuMSwxMS43IDYuOSw1LjggIi8+DQo8L3N2Zz4NCg==";
    --breadcrumb-divider-height: 0.64137rem;
    --breadcrumb-divider-width: 2.45rem;
    /* Pagination/Pager */
    --pagination-padding-y: var(--btn-padding-y);
    --pagination-padding-x: var(--btn-padding-x);
    --pagination-color: var(--gray);
    --pagination-bg: var(--white);
    --pagination-border-width: 1px;
    --pagination-border-color: var(--gray);
    --pagination-hover-color: var(--white);
    --pagination-hover-bg: var(--brand-gray);
    --pagination-hover-border: var(--gray);
    --pagination-active-color: var(--white);
    --pagination-active-bg: var(--brand-gray);
    --pagination-active-border: transparent;
    --pagination-disabled-color: var(--gray-light);
    --pagination-disabled-bg: var(--white);
    --pagination-disabled-border: var(--gray-light);
    --pagination-icon-color: var(--gray);
    --pager-border-color: var(--gray);
    /* Accordions */
    --accordion-icon-size: var(--icon-size-md);
    --accordion-title-font-size: var(--font-size-h4);
    --accordion-border-color: var(--brand-gray);
    --accordion-padding-y: var(--spacer);
    --accordion-padding-x: var(--spacer);
    /* Cards */
    --card-bg: var(--white);
    --card-padding-y: 1.596rem;
    --card-padding-x: 1.596rem;
    --card-footer-color: var(--gray);
    --card-footer-font-size: var(--font-size-small);
    --card-icon-size: var(--icon-size-xs);
    /* Sections */
    --section-header-font-size: var(--font-size-h4);
    --section-header-font-size-lg: var(--font-size-h3);
    --section-header-margin: 1.10833rem;
    --section-padding-desktop: 3.30947rem;
    --section-padding-mobile: 1.9152rem;
    --section-bg: var(--gray-lighter);
    --aside-item-padding-desktop: 1.9152rem;
    --aside-item-padding-mobile: var(--spacer);
    /* Search */
    --search-height: 74px;
    --search-opacity: 0.8;
    --search-color: var(--white);
    /* Sliders */
    --icon-right-arrow: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMS45MiAyMC4xNyI+PHRpdGxlPnNpbW9uX2ljb248L3RpdGxlPjxwb2x5Z29uIHBvaW50cz0iMS44MyAwIDAgMS44MyA4LjI1IDEwLjA4IDAgMTguMzMgMS44MyAyMC4xNyAxMS45MiAxMC4wOCAxLjgzIDAiLz48L3N2Zz4=");
    --icon-left-arrow: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA2LjkgMTEuNyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNi45IDExLjc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjx0aXRsZT5zaW1vbl9pY29uPC90aXRsZT4NCjxwb2x5Z29uIHBvaW50cz0iNS44LDExLjcgNi45LDEwLjYgMi4xLDUuOCA2LjksMS4xIDUuOCwwIDAsNS44ICIvPg0KPC9zdmc+DQo=");
    --icon-loader: "https://assets.simon.com/css/ajax-loader.gif";
    /* Video */
    --icon-sound: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzNS45IDMxLjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM1LjkgMzEuNTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPHRpdGxlPnNpbW9uX2ljb248L3RpdGxlPg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIyMC4xLDAgOS40LDkuMSAwLDkuMSAwLDIyLjQgOS40LDIyLjQgMjAuMSwzMS41ICIvPg0KPC9zdmc+DQo=");
    --icon-mute: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzNS45IDMxLjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM1LjkgMzEuNTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPHRpdGxlPnNpbW9uX2ljb248L3RpdGxlPg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIyMC4xLDAgOS40LDkuMSAwLDkuMSAwLDIyLjQgOS40LDIyLjQgMjAuMSwzMS41ICIvPg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzNS45LDIwLjEgMzEuMSwxNS40IDM1LjksMTAuNiAzNC44LDkuNSAzMC4xLDE0LjMgMjUuMyw5LjUgMjQuMywxMC42IDI5LDE1LjQgMjQuMywyMC4xIDI1LjMsMjEuMiANCgkzMC4xLDE2LjQgMzQuOCwyMS4yICIvPg0KPC9zdmc+DQo=");
    --icon-pause: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzNS45IDMxLjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM1LjkgMzEuNTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPHRpdGxlPnNpbW9uX2ljb248L3RpdGxlPg0KPGc+DQoJPHJlY3QgeD0iNi41IiBjbGFzcz0ic3QwIiB3aWR0aD0iNy42IiBoZWlnaHQ9IjMxLjUiLz4NCgk8cmVjdCB4PSIyMS44IiBjbGFzcz0ic3QwIiB3aWR0aD0iNy42IiBoZWlnaHQ9IjMxLjUiLz4NCjwvZz4NCjwvc3ZnPg0K");
    /* Z-index master list */
    --zindex-navbar: 100;
    --zindex-dropdown-backdrop: 990;
    --zindex-dropdown: 1000;
    --zindex-popover: 1060;
    --zindex-tooltip: 1070;
    --zindex-search-results: 1010;
    --zindex-search: 1020;
    --zindex-navbar-fixed: 1030;
    --zindex-navbar-sticky: 1030;
    --zindex-navbar-sidepanel-mask: 1040;
    --zindex-navbar-sidepanel: 1050;
    --zindex-navbar-toggle: 1060;
    --zindex-modal-bg: 1070;
    --zindex-modal: 1080;
}

/*
 * Custom Media Queries for easy reuse of media queries (https://drafts.csswg.org/mediaqueries/#custom-mq)
*/

@custom-media --xl-viewport-up (min-width: 1200px);
@custom-media --lg-viewport-up (min-width: 992px);
@custom-media --md-viewport-up (min-width: 768px);
@custom-media --sm-viewport-up (min-width: 544px);
@custom-media --xl-viewport-down (max-width: 1199px);
@custom-media --lg-viewport-down (max-width: 991px);
@custom-media --md-viewport-down (max-width: 767px);
@custom-media --sm-viewport-down (max-width: 543px);
@custom-media --retina-in-dpi (min-resolution: 192dpi);
@custom-media --retina-in-dppx (min-resolution: 2dppx);
@custom-media --retina (min-resolution: 192dpi), (min-resolution: 2dppx);

/*
 * Sass variables needed for @mixin functionality
*/
$btn-primary-color: var(--btn-primary-color);
$btn-primary-bg: var(--btn-primary-bg);
$btn-primary-border: var(--btn-primary-border);
$btn-primary-color-active: var(--btn-primary-color-active);
$btn-primary-bg-active: var(--btn-primary-bg-active);
$btn-primary-color-active-focus: var(--btn-primary-color-active-focus);
$btn-primary-outline-color: var(--black);
$btn-primary-outline-color-active: var(--brand-gray);

$btn-secondary-color: var(--btn-secondary-color);
$btn-secondary-bg: var(--btn-secondary-bg);
$btn-secondary-border: var(--btn-secondary-border);
$btn-secondary-color-active: var(--btn-secondary-color-active);
$btn-secondary-bg-active: var(--btn-secondary-bg-active);
$btn-secondary-color-active-focus: var(--btn-secondary-color-active-focus);
$btn-secondary-outline-color: var(--white);
$btn-secondary-outline-color-active: var(--brand-gray);

$btn-padding-y: var(--btn-padding-y);
$btn-padding-x: var(--btn-padding-x);
$btn-font-size: var(--btn-font-size);
$btn-border-radius: var(--btn-border-radius);

$brand-error: var(--brand-error);
